"use client"

import { Button } from "@/components/ui/button"
import { Drawer, DrawerContent, DrawerFooter, DrawerPositionEnum, DrawerTitle } from "@/components/ui/drawer"
import Loader2 from "@/components/ui/loader2"
import { MergedInventory } from "@/types/Inventory"
import { RefreshCcw } from "lucide-react"
import React, { ReactElement } from "react"
import { useIntl } from "react-intl"
import { cn } from "@/lib/utils"
import usePutawayInventories from "@/hooks/use-putaway-inventories"
import useSetPutawayState from "@/api/putaway/useSetPutawayState"
import usePutawayState from "@/api/putaway/usePutawayState"
import useSetLocationLayout from "@/api/location/useSetLocationLayout"
import { ScrollArea } from "@/components/ui/scroll-area"
import InventoryNode from "./inventory-node"

const SelectItemDrawer: React.FC = (): ReactElement => {
    const intl = useIntl()

    const {
        data: { openAddItemDrawer },
    } = usePutawayState()
    const { mutate: mutatePutawayState } = useSetPutawayState()
    const { mutate: mutateLocationLayout } = useSetLocationLayout()

    const {
        data: putawayInventories,
        isFetching,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        refetch,
    } = usePutawayInventories({
        enableHook: openAddItemDrawer,
    })
    const inventories = putawayInventories?.pages.flatMap((page) => page.data) ?? []

    const onCloseItemForm = () => {
        mutateLocationLayout({
            isOpen: false,
            selectedId: 0,
            selectedStorageBinId: 0,
            selectableStorageBinFn: undefined,
        })
        mutatePutawayState({
            openAddItemDrawer: false,
            selectedInventory: undefined,
            selectedTransaction: undefined,
            putawayDetail: {
                data: undefined,
                editMode: false,
            },
        })
    }

    return (
        <Drawer shouldScaleBackground direction="left" dismissible={false} open={openAddItemDrawer} modal={false}>
            <DrawerContent hideHandle position={DrawerPositionEnum.LEFT} className={cn("h-full w-1/3 min-w-[500px]")}>
                <div className="space-y-4">
                    <DrawerTitle>{intl.formatMessage({ id: "items_to_putaway" })}</DrawerTitle>
                    <p className="text-muted-foreground">{intl.formatMessage({ id: "items_to_putaway_desc" })}</p>
                    <div className="flex flex-row-reverse items-center justify-between">
                        <Button onClick={() => refetch()}>
                            <RefreshCcw />
                        </Button>
                    </div>

                    {isFetching && !isFetchingNextPage ? (
                        <div className="flex items-center justify-center">
                            <Loader2 className="h-16 w-16" />
                        </div>
                    ) : (
                        <ScrollArea className="h-[75vh] pr-4">
                            <div className="flex flex-col gap-2">
                                {inventories.map((inventory: MergedInventory, index: number) => (
                                    <InventoryNode key={inventory.merged_id} index={index} inventory={inventory} inventoriesLength={inventories.length} />
                                ))}
                                {hasNextPage && (
                                    <Button className="h-max rounded-sm py-1" onClick={() => fetchNextPage()}>
                                        {isFetchingNextPage ? <Loader2 className="h-4 w-4" /> : intl.formatMessage({ id: "load_more" })}
                                    </Button>
                                )}
                            </div>
                        </ScrollArea>
                    )}
                </div>
                <DrawerFooter>
                    <Button variant="secondary" onClick={onCloseItemForm}>
                        {intl.formatMessage({ id: "close" })}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default SelectItemDrawer
