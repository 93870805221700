"use client"

import { Button } from "@/components/ui/button"
import { Drawer, DrawerContent, DrawerFooter, DrawerPositionEnum, DrawerTitle } from "@/components/ui/drawer"
import React, { ReactElement } from "react"
import { useIntl } from "react-intl"
import { cn } from "@/lib/utils"
import useSalesOrderState from "@/api/sales-order/useSalesOrderState"
import useSetSalesOrderState from "@/api/sales-order/useSetSalesOrderState"
import { SalesOrderDetail } from "@/types/SalesOrderDetail"
import DetailNode from "./detail-node"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Separator } from "@/components/ui/separator"

const SalesOrderSelectItemDrawer: React.FC = (): ReactElement => {
    const intl = useIntl()

    const {
        data: { selectedHeaders, selectedHeader, isSplitMode, selectedSplitItems },
    } = useSalesOrderState()
    const details = selectedHeader?.details ?? []
    const splitDetails = selectedHeader?.splitDetails ?? []

    const { mutate: mutateSalesOrderState } = useSetSalesOrderState()

    const onCloseItemForm = () => {
        mutateSalesOrderState({
            selectedHeader: undefined,
            isSplitMode: false,
        })
    }

    const handleSplitSalesOrder = () => {
        if (selectedHeader) {
            const filteredDetails = selectedHeader.details.filter((detail) => !selectedSplitItems.includes(detail))
            const fully_sufficient = filteredDetails.every((detail) => detail.is_sufficient)
            const partially_sufficient = !filteredDetails.every((detail) => detail.is_sufficient) && filteredDetails.some((detail) => detail.is_sufficient)

            const splitHeader = {
                ...selectedHeader,
                splitDetails: [...(selectedHeader.splitDetails ?? []), ...selectedSplitItems],
                fully_sufficient,
                partially_sufficient,
                details: filteredDetails,
            }

            mutateSalesOrderState({
                selectedHeader: undefined,
                selectedHeaders: selectedHeaders.map((header) => (header.id === selectedHeader.id ? splitHeader : header)),
                isSplitMode: false,
                selectedSplitItems: [],
            })
        }
    }

    const handleSelectAllInsufficient = () => {
        mutateSalesOrderState({
            selectedSplitItems: details.filter((detail) => !detail.is_sufficient),
        })
    }

    return (
        <Drawer shouldScaleBackground direction="left" open={!!selectedHeader} onOpenChange={(open) => !open && onCloseItemForm()}>
            <DrawerContent hideHandle position={DrawerPositionEnum.LEFT} className={cn("h-full w-2/5 min-w-[500px]")}>
                <div className="space-y-4">
                    <DrawerTitle>
                        {intl.formatMessage({ id: "sales_order_lines" })}
                        {isSplitMode && (
                            <React.Fragment>
                                &nbsp;•&nbsp;
                                <span className="text-warning">{intl.formatMessage({ id: "split_mode" })}</span>
                            </React.Fragment>
                        )}
                    </DrawerTitle>
                    <p className="text-muted-foreground">
                        {intl.formatMessage({ id: "sales_order_lines_desc" })}. &nbsp;
                        {isSplitMode && <span className="text-warning">{intl.formatMessage({ id: "split_mode_desc" })}</span>}
                    </p>

                    {isSplitMode && (
                        <div className="flex flex-row-reverse pr-4">
                            <Button onClick={handleSelectAllInsufficient}>{intl.formatMessage({ id: "select_all_insufficient" })}</Button>
                        </div>
                    )}

                    <ScrollArea className="h-[75vh] pr-4">
                        <div className="flex flex-col gap-2">
                            {details.map((detail: SalesOrderDetail, index: number) => (
                                <DetailNode key={`sales_order_detail-${detail.id}`} index={index} detail={detail} lines={details.length} />
                            ))}
                            {splitDetails.length > 0 && (
                                <React.Fragment>
                                    <Separator />
                                    <p className="font-bold">{`${intl.formatMessage({ id: "split_sales_order" })} ${intl.formatMessage({ id: "items" })}`}</p>
                                    {splitDetails.map((detail: SalesOrderDetail, index: number) => (
                                        <DetailNode key={`sales_order_detail-${detail.id}`} index={index} detail={detail} isSplitDetail lines={details.length} />
                                    ))}
                                </React.Fragment>
                            )}
                        </div>
                        <div className="flex flex-col gap-2"></div>
                    </ScrollArea>
                </div>
                <DrawerFooter>
                    {isSplitMode && (
                        <Button disabled={selectedSplitItems.length === 0} onClick={handleSplitSalesOrder}>
                            {intl.formatMessage({ id: "split_sales_order" })}
                        </Button>
                    )}
                    <Button variant="secondary" onClick={onCloseItemForm}>
                        {intl.formatMessage({ id: "close" })}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default SalesOrderSelectItemDrawer
