import useSetStatusLogState from "@/api/status-log/useSetStatusLogState"
import useStatusLogState from "@/api/status-log/useStatusLogState"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "@/components/ui/sheet"
import { Timeline, TimelineContent, TimelineDot, TimelineHeading, TimelineItem, TimelineLine } from "@/components/ui/timeline"
import { errorStatus, GeneralStatusEnum, GeneralStatusLabel, optionalStatus } from "@/constants/GeneralStatus"
import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import { getRelativeTime } from "@/lib/dayjs"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { SalesOrderStatusSequence } from "@/constants/SalesOrderStatus"
import { GoodsReceivedStatusSequence } from "@/constants/GoodsReceivedStatus"
import { PutawayStatusSequence } from "@/constants/PutawayStatus"
import { PickingStatusSequence } from "@/constants/PickingStatus"
import { DeliveryNoteStatusSequence } from "@/constants/DeliveryNoteStatus"
import { PurchaseOrderStatusSequence } from "@/constants/PurchaseOrderStatus"
import { PackingStatusSequence } from "@/constants/PackingStatus"

interface Props {}

interface StatusSequenceProps {
    status: GeneralStatusEnum
    optional: boolean
    dot?: "done" | "default"
    line?: boolean
    lineDone?: boolean
    startTime?: Date | string
    createdBy?: {
        name: string
        email: string
    }
}

const StatusLogSheet: React.FC<Props> = () => {
    const intl = useIntl()

    const {
        data: { open, originalDocument, data = [], statusType },
    } = useStatusLogState()
    const { mutate } = useSetStatusLogState()

    const sequenceByStatusType = useMemo(() => {
        switch (statusType) {
            case "sales_order":
                return SalesOrderStatusSequence
            case "goods_received":
                return GoodsReceivedStatusSequence
            case "putaway":
                return PutawayStatusSequence
            case "picking":
                return PickingStatusSequence
            case "packing":
                return PackingStatusSequence
            case "delivery_note":
                return DeliveryNoteStatusSequence
            case "purchase_order":
                return PurchaseOrderStatusSequence

            default:
                return []
        }
    }, [statusType])

    const renderStatusSequence = useMemo(() => {
        if (!originalDocument?.status) return []
        let currentIndex = -1
        const statuses: StatusSequenceProps[] = []

        if (data.length > 0) {
            const initialStatus = data[0]
            const initialStatusIndex = sequenceByStatusType.findIndex((status) => status.status === initialStatus.from)

            if (initialStatusIndex > -1) {
                const startingSlice = sequenceByStatusType.slice(0, initialStatusIndex).filter((status) => !optionalStatus.includes(status.status))
                statuses.push(...startingSlice)
            }

            statuses.push(
                ...data.flatMap((statusLog, index) => {
                    if (index === 0) {
                        const isStartingStatus = sequenceByStatusType[0].status === statusLog.from

                        return [
                            {
                                status: statusLog.from as GeneralStatusEnum,
                                optional: false,
                                startTime: isStartingStatus ? originalDocument.created_at : originalDocument.updated_at,
                                createdBy: isStartingStatus ? originalDocument.created_by : originalDocument.updated_by,
                            },
                            {
                                status: statusLog.to as GeneralStatusEnum,
                                optional: false,
                                startTime: statusLog.created_at,
                                createdBy: statusLog.created_by,
                            },
                        ]
                    } else {
                        return {
                            status: statusLog.to as GeneralStatusEnum,
                            optional: false,
                            startTime: statusLog.created_at,
                            createdBy: statusLog.created_by,
                        }
                    }
                })
            )

            const endStatus = data[data.length - 1]
            const endStatusIndex = sequenceByStatusType.findIndex((status) => status.status === endStatus.to)

            if (endStatusIndex > -1) {
                const endingSlice = sequenceByStatusType.slice(endStatusIndex + 1).filter((status) => !optionalStatus.includes(status.status))
                statuses.push(...endingSlice)
            } else if (errorStatus.includes(endStatus.to as GeneralStatusEnum)) {
                statuses.push(...sequenceByStatusType)
            }

            currentIndex = statuses.findLastIndex((status) => status.status === originalDocument.status)
        } else {
            if (errorStatus.includes(originalDocument.status as GeneralStatusEnum)) {
                statuses.push({
                    status: originalDocument.status as GeneralStatusEnum,
                    optional: false,
                    startTime: originalDocument.updated_at,
                    createdBy: originalDocument.updated_by,
                })
            }

            statuses.push(...sequenceByStatusType)
            currentIndex = statuses.findIndex((status) => status.status === originalDocument.status)
        }

        return statuses.map((status, index) => {
            if (index < currentIndex) {
                return {
                    ...status,
                    status: GeneralStatusLabel[status.status],
                    dot: errorStatus.includes(status.status) ? "error" : "done",
                    line: true,
                    lineDone: true,
                }
            } else if (index === currentIndex) {
                return {
                    ...status,
                    status: GeneralStatusLabel[status.status],
                    dot: errorStatus.includes(status.status) ? "error" : "done",
                    line: index < statuses.length - 1,
                    lineDone: false,
                }
            } else {
                return {
                    ...status,
                    status: GeneralStatusLabel[status.status],
                    dot: "default",
                    line: index < statuses.length - 1,
                }
            }
        }) as StatusSequenceProps[]
    }, [originalDocument, sequenceByStatusType, data])

    return (
        <Sheet
            open={open}
            onOpenChange={(open) => {
                if (open) {
                    mutate({
                        open: true,
                    })
                } else {
                    mutate({
                        open,
                        originalDocument: undefined,
                        data: [],
                        statusType: undefined,
                    })
                }
            }}
        >
            <SheetContent className="w-[400px]  space-y-4 pt-[6.5rem]">
                <SheetHeader className="fixed top-0 pt-6">
                    <SheetTitle>{intl.formatMessage({ id: "status_logs" })}</SheetTitle>
                    <SheetDescription>{intl.formatMessage({ id: "status_logs_desc" })}</SheetDescription>
                </SheetHeader>
                <ScrollArea className="h-[calc(100%-1.5rem)]">
                    <Timeline>
                        {renderStatusSequence.map((status, index) => (
                            <TimelineItem key={`status-${index}`}>
                                <TimelineHeading className="text-md font-bold">{status.status}</TimelineHeading>
                                <TimelineDot status={status.dot} />
                                {status.line && <TimelineLine done={status.lineDone} />}
                                <TimelineContent className="w-full">
                                    {status.createdBy && (
                                        <div className="flex items-center gap-2">
                                            <Avatar className="rounded-none p-0.5">
                                                <AvatarImage src={""} alt={""} />
                                                <AvatarFallback className="rounded-sm">{status.createdBy.name[0]}</AvatarFallback>
                                            </Avatar>
                                            <div>
                                                <p className="text-sm font-bold">{status.createdBy.name}</p>
                                                <p className="text-sm">{status.createdBy.email}</p>
                                            </div>
                                        </div>
                                    )}
                                    {status.startTime && <p className="text-right text-sm text-muted-foreground">{getRelativeTime(status.startTime)}</p>}
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                        {/* {data.length > 0 ? (
                            <React.Fragment>
                                {data.map((statusLog, index) => (
                                    <React.Fragment key={`status-log-${index}`}>
                                        <TimelineItem>
                                            <TimelineHeading className="text-md font-bold">{GeneralStatusLabel[statusLog.from]}</TimelineHeading>
                                            <TimelineDot status="done" />
                                            <TimelineLine done={index < data.length} />
                                            <TimelineContent className="w-full">
                                                <p className="text-right text-sm text-muted-foreground">{getRelativeTime(startTime)}</p>
                                            </TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineHeading className="text-md font-bold">{GeneralStatusLabel[statusLog.to]}</TimelineHeading>
                                            <TimelineDot status="done" />
                                            {balanceStatusSequence.length > 0 && <TimelineLine />}
                                            <TimelineContent className="w-full space-y-2 py-2">
                                                <div className="flex items-center gap-2">
                                                    <Avatar className="rounded-none p-0.5">
                                                        <AvatarImage src={""} alt={""} />
                                                        <AvatarFallback className="rounded-sm">{statusLog.created_by.name[0]}</AvatarFallback>
                                                    </Avatar>
                                                    <div>
                                                        <p className="text-sm font-bold">{statusLog.created_by.name}</p>
                                                        <p className="text-sm">{statusLog.created_by.email}</p>
                                                    </div>
                                                </div>
                                                <p className="text-right text-sm text-muted-foreground">{getRelativeTime(statusLog.created_at)}</p>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </React.Fragment>
                                ))}

                                {balanceStatusSequence.map((status, index) => (
                                    <TimelineItem key={`balance-status_${index}`}>
                                        <TimelineHeading className="text-md font-bold">{GeneralStatusLabel[status.status]}</TimelineHeading>
                                        <TimelineDot status={status.statusType} />
                                        {index < balanceStatusSequence.length - 1 && <TimelineLine />}
                                    </TimelineItem>
                                ))}
                            </React.Fragment>
                        ) : (
                            balanceStatusSequence.map((status, index) => (
                                <TimelineItem key={`no-data-status_${index}`}>
                                    <TimelineHeading className="text-md font-bold">{GeneralStatusLabel[status.status]}</TimelineHeading>
                                    <TimelineDot status={status.statusType} />
                                    {index < balanceStatusSequence.length - 1 && <TimelineLine />}
                                </TimelineItem>
                            ))
                        )} */}
                    </Timeline>
                </ScrollArea>
            </SheetContent>
        </Sheet>
    )
}

export default StatusLogSheet
