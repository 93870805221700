import { SalesOrderState } from "@/types/SalesOrder"

const InitialSalesOrderState: SalesOrderState = {
    selectedHeader: undefined,
    selectedHeaders: [],
    selectedItems: [],

    isSplitMode: false,
    selectedSplitItems: [],

    selectedBranch: undefined,
}

export default InitialSalesOrderState
