import { useQuery } from "@tanstack/react-query"
import InitialPutawayState from "./InitialPutawayState"
import { PutawayState } from "@/types/Putaway"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return useQuery<PutawayState>({
        queryKey: ["putaway-state"],
        initialData: InitialPutawayState,
    })
}
