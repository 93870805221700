import { PutawayDetail } from "@/types/PutawayDetail"
import { MergedTransaction } from "@/types/StockTrans"
import Decimal from "decimal.js"

export enum StockCountStatusEnum {
    DRAFT = "draft",
    IN_PROGRESS = "in_progress",
    COMPLETED = "completed",
}

export const StockCountStatusLabel = {
    [StockCountStatusEnum.DRAFT]: "Draft",
    [StockCountStatusEnum.IN_PROGRESS]: "In Progress",
    [StockCountStatusEnum.COMPLETED]: "Completed",
}

export const StockCountStatusOptions = [
    { value: StockCountStatusEnum.DRAFT, label: StockCountStatusEnum.DRAFT },
    { value: StockCountStatusEnum.IN_PROGRESS, label: StockCountStatusEnum.IN_PROGRESS },
    { value: StockCountStatusEnum.COMPLETED, label: StockCountStatusEnum.COMPLETED },
]

export const StockCountStatusSelectOptions = [
    { value: StockCountStatusEnum.DRAFT, label: StockCountStatusLabel[StockCountStatusEnum.DRAFT] },
    { value: StockCountStatusEnum.IN_PROGRESS, label: StockCountStatusLabel[StockCountStatusEnum.IN_PROGRESS] },
    { value: StockCountStatusEnum.COMPLETED, label: StockCountStatusLabel[StockCountStatusEnum.COMPLETED] },
]

const LOW_STOCK_THRESHOLD = 0.2
const HIGH_STOCK_THRESHOLD = 0.9

export const getPAStockTransactionUnitBalance = (transaction: MergedTransaction | undefined, putawayDetails: PutawayDetail[], selectedPutawayDetail?: PutawayDetail) => {
    if (!transaction) return { color: "muted", balance: "0" }

    const mergedBalance = putawayDetails.reduce((acc: number, detail: PutawayDetail) => {
        if (detail.doc_hdr_id === transaction.doc_hdr_id && detail.item_id === transaction.item_id && detail.var_combo_id === transaction.var_combo_id) {
            const uomRate = new Decimal(detail.uom?.inventory_item?.pivot?.uom_rate ?? "1")
            const quantity = new Decimal(detail.qty).times(uomRate)

            return acc + quantity.toNumber()
        }

        return acc
    }, 0)

    const initialBalance = new Decimal(transaction.total_unit_qty)
    let balance = new Decimal(transaction.total_unit_qty)

    if (!!selectedPutawayDetail) {
        const selectedDetailUomRate = new Decimal(selectedPutawayDetail.uom?.inventory_item?.pivot?.uom_rate ?? "1")
        balance = balance.minus(mergedBalance)
        const selectedPickingUnitQty = new Decimal(selectedPutawayDetail.qty).times(selectedDetailUomRate)
        balance = balance.plus(selectedPickingUnitQty)
    }

    return {
        color: balance.isZero()
            ? "muted"
            : balance.lessThanOrEqualTo(initialBalance.times(LOW_STOCK_THRESHOLD))
              ? "destructive"
              : balance.lessThanOrEqualTo(initialBalance.times(HIGH_STOCK_THRESHOLD))
                ? "warning"
                : "success",
        balance: balance.toString(),
    }
}
