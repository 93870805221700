import enUS_A from "./a.json"
import enUS_B from "./b.json"
import enUS_C from "./c.json"
import enUS_D from "./d.json"
import enUS_E from "./e.json"
import enUS_F from "./f.json"
import enUS_G from "./g.json"
import enUS_H from "./h.json"
import enUS_I from "./i.json"
import enUS_L from "./l.json"
import enUS_M from "./m.json"
import enUS_N from "./n.json"
import enUS_O from "./o.json"
import enUS_P from "./p.json"
import enUS_Q from "./q.json"
import enUS_R from "./r.json"
import enUS_S from "./s.json"
import enUS_T from "./t.json"
import enUS_U from "./u.json"
import enUS_V from "./v.json"
import enUS_W from "./w.json"
import enUS_X from "./x.json"
import enUS_Y from "./y.json"

const enUS = {
    ...enUS_A,
    ...enUS_B,
    ...enUS_C,
    ...enUS_D,
    ...enUS_E,
    ...enUS_F,
    ...enUS_G,
    ...enUS_H,
    ...enUS_I,
    ...enUS_L,
    ...enUS_M,
    ...enUS_N,
    ...enUS_O,
    ...enUS_P,
    ...enUS_Q,
    ...enUS_R,
    ...enUS_S,
    ...enUS_T,
    ...enUS_U,
    ...enUS_V,
    ...enUS_W,
    ...enUS_X,
    ...enUS_Y,
}

export default enUS
