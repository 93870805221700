import useFetchPutawayDetails from "@/api/putaway/useFetchPutawayDetails"
import usePutawayState from "@/api/putaway/usePutawayState"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import Loader2 from "@/components/ui/loader2"
import { getPAStockTransactionUnitBalance } from "@/constants/StockCountStatus"
import { PutawayDetail } from "@/types/PutawayDetail"
import Decimal from "decimal.js"
import { useParams } from "next/navigation"
import React, { ReactElement, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

interface Props {
    isLoading: boolean
    isSubmitting: boolean
    readonly?: boolean
}

const QuantityField: React.FC<Props> = ({ isLoading, isSubmitting, readonly }): ReactElement => {
    const intl = useIntl()
    const { id } = useParams()
    const form = useFormContext()
    const uom = form.watch("uom")

    const [maxUomQty, setMaxUomQty] = useState<number>(0)

    const {
        data: {
            selectedTransaction,
            putawayDetail: { data: selectedPutawayDetail },
        },
    } = usePutawayState()
    const { data: detailsData = [], isFetching } = useFetchPutawayDetails({
        enableHook: false,
        id: Number(id),
    })
    const putawayDetails: PutawayDetail[] = detailsData?.data ?? []

    const { balance } = getPAStockTransactionUnitBalance(selectedTransaction, putawayDetails, selectedPutawayDetail)

    useEffect(() => {
        if (uom) {
            const uomRate = new Decimal(uom.pivot?.uom_rate ?? "1")
            const unitBalance = new Decimal(balance)
            let convertedQty = unitBalance.dividedToIntegerBy(uomRate).toNumber()

            if (uom.allow_decimal) {
                convertedQty = unitBalance.dividedBy(uomRate).toNumber()
            }

            const currentQty = form.getValues("qty")

            setMaxUomQty(convertedQty)

            if (currentQty > convertedQty) {
                form.setValue("qty", convertedQty)
            }
        }
    }, [uom])

    return (
        <FormField
            control={form.control}
            name="qty"
            render={({ field: { onChange, onBlur, ...rest } }) => (
                <FormItem className="col-span-1">
                    <FormLabel>{intl.formatMessage({ id: "qty" })}</FormLabel>
                    <FormControl>
                        <Input
                            disabled={isLoading || isSubmitting || !!readonly}
                            loading={isLoading}
                            type="number"
                            step={1}
                            min={0}
                            max={maxUomQty}
                            postfix={isFetching ? <Loader2 /> : `/${maxUomQty}`}
                            onBlur={(e) => {
                                const value = Number(e.target.value)
                                const max = Number(maxUomQty ?? 0)

                                onChange(e.target.value === "" ? 0 : value > max ? max : value)
                            }}
                            onChange={onChange}
                            {...rest}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

export default QuantityField
