import { toast } from "sonner"

export const ToastError = (title: string, error?: any) => {
    if (typeof error === "string") {
        toast.error(title, {
            description: error,
        })
    } else if (typeof error?.message === "string") {
        toast.error(title, {
            description: error.message,
        })
    } else if (error?.message) {
        for (const key in error.message) {
            toast.error(title, {
                description: error.message[key],
            })
        }
    } else if (error) {
        for (const key in error) {
            toast.error(title, {
                description: error[key],
            })
        }
    } else {
        toast.error(title)
    }
}

export const ToastSuccess = (title: string, success: any) => {
    if (typeof success === "string") {
        toast.success(title, {
            description: success,
        })
    } else if (typeof success.message === "string") {
        toast.success(title, {
            description: success.message,
        })
    } else {
        if (success.message) {
            for (const key in success.message) {
                toast.success(title, {
                    description: success.message[key],
                })
            }
        } else {
            for (const key in success) {
                toast.success(title, {
                    description: success[key],
                })
            }
        }
    }
}
