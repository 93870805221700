import { useMutation, useQueryClient } from "@tanstack/react-query"
import InitialState, { AlertDialogState } from "./InitialState"

export default () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (values: Partial<AlertDialogState>) => {
            const alertDialog = queryClient.getQueryData<AlertDialogState>(["alert-dialog"])
            return {
                ...InitialState,
                ...alertDialog,
                ...values,
            }
        },
        onSuccess: (data) => {
            queryClient.setQueryData<AlertDialogState>(["alert-dialog"], data)
        },
    })
}
