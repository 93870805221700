import useAlertDialogState from "@/api/alert-dialog/useAlertDialogState"
import useSetAlertDialogState from "@/api/alert-dialog/useSetAlertDialogState"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { AlertDialogContent } from "@radix-ui/react-alert-dialog"
import React, { PropsWithChildren, ReactElement } from "react"
import { useIntl } from "react-intl"

const AlertDialogProvider: React.FC<PropsWithChildren> = ({ children }): ReactElement => {
    const intl = useIntl()

    const {
        data: { isOpen, title, content, okVariant, onOk, onCancel },
    } = useAlertDialogState()

    const { mutate } = useSetAlertDialogState()

    const onClose = () => {
        mutate({
            isOpen: false,
            title: "",
            content: null,
            okVariant: "default",

            onOk: () => {},
            onCancel: () => {},
        })
    }

    const handleClose = () => {
        onCancel?.()
        onClose()
    }

    const handleOk = async () => {
        onOk?.()
        onClose()
    }

    return (
        <React.Fragment>
            {children}
            <Dialog open={isOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogDescription>{content}</DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="secondary" onClick={handleClose}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button variant={okVariant} onClick={handleOk}>
                            {intl.formatMessage({ id: "confirm" })}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default AlertDialogProvider
