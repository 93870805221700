import React, { ReactElement } from "react"
import { useFormContext } from "react-hook-form"
import { cn } from "@/lib/utils"

interface Props {
    fieldName: string
}

const FormMessageWrapper: React.FC<Props> = ({ fieldName }): ReactElement => {
    const form = useFormContext()

    const errors = Object.entries(form.formState.errors[fieldName] ?? {}).map(([key, value]) => value)
    const firstError = errors.length > 0 ? errors[0] : undefined

    return <p className={cn("text-sm font-medium text-destructive")}>{firstError?.message}</p>
}

export default FormMessageWrapper
