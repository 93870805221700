export enum GeneralEnum {
    NOT_NULL = "not_null",
    NULL = "null",
    EXACT = "exact",
    LIKE = "like",
    NOT = "not",
    GREATER_THAN = "greater_than",
    LESS_THAN = "less_than",
    GREATER_THAN_OR_EQUAL = "greater_than_or_equal",
    LESS_THAN_OR_EQUAL = "less_than_or_equal",
}
