import * as React from "react"

import { cn } from "@/lib/utils"
import Loader2 from "./loader2"

type BaseInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "prefix">
export interface InputProps extends BaseInputProps {
    loading?: boolean
    prefix?: React.ReactNode
    postfix?: React.ReactNode
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, loading, type, prefix, postfix, ...props }, ref) => {
    const postfixRef = React.useRef<HTMLSpanElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        if (type === "number") event.currentTarget.select()
    }

    return (
        <div className="relative flex items-center rounded-md bg-background">
            {prefix && <span className="absolute inset-y-0 left-0 flex items-center pl-3">{prefix}</span>}
            <input
                type={type}
                className={cn(
                    "flex h-10 w-full rounded-md border border-input bg-transparent py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                    className,
                    prefix ? "pl-10" : "pl-3"
                )}
                ref={ref}
                onClick={handleClick}
                style={{
                    paddingRight: `calc(0.75rem + ${loading || postfixRef.current || type === "password" ? "0.75rem" : "0px"} + ${loading ? "1.75rem" : "0px"} + ${
                        postfixRef.current ? `${postfixRef.current.offsetWidth}px` : "0px"
                    })`,
                }}
                {...props}
            />
            <div className="absolute right-3 flex items-center justify-end gap-2">
                {loading && <Loader2 />}
                {postfix && <span ref={postfixRef}>{postfix}</span>}
            </div>
        </div>
    )
})
Input.displayName = "Input"

export { Input }
