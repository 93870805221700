import useFetchPutawayDetails from "@/api/putaway/useFetchPutawayDetails"
import useSetPutawayState from "@/api/putaway/useSetPutawayState"
import { Icons } from "@/components/icons"
import { Separator } from "@/components/ui/separator"
import { getPAStockTransactionUnitBalance } from "@/constants/StockCountStatus"
import { getItemVariantName, renderUomQuantities } from "@/lib/MapHelper"
import { cn } from "@/lib/utils"
import { MergedInventory } from "@/types/Inventory"
import { MergedTransaction } from "@/types/StockTrans"
import { useParams } from "next/navigation"
import React, { ReactElement } from "react"
import { useIntl } from "react-intl"

interface Props {
    index: number
    inventory: MergedInventory
    inventoriesLength: number
}

const InventoryNode: React.FC<Props> = ({ index, inventory, inventoriesLength }): ReactElement => {
    const intl = useIntl()
    const { id } = useParams()

    const { data: putawayDetailsData, isFetching: isFetchingDetails } = useFetchPutawayDetails({
        enableHook: false,
        id: Number(id),
    })
    const putawayDetails = Array.isArray(putawayDetailsData?.data) ? putawayDetailsData.data : []

    const { mutate: mutatePutawayState } = useSetPutawayState()

    return (
        <React.Fragment>
            {index > 0 && index < inventoriesLength && <Separator />}
            <div className="flex flex-col gap-2 rounded bg-muted p-4">
                <div className="flex items-center justify-between">
                    <div className="max-w-80">
                        <p className="truncate font-bold">{inventory.item?.code}</p>
                        <p className="truncate text-sm text-muted-foreground">{getItemVariantName(inventory.var_combo)}</p>
                    </div>

                    <p className="font-bold">{renderUomQuantities(inventory.total_unit_qty, inventory.item?.uoms_descending)}</p>
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-sm font-bold text-muted-foreground">{intl.formatMessage({ id: "from" })}</p>
                    {!inventory.transactions ? (
                        <p className="text-sm text-muted-foreground">{intl.formatMessage({ id: "no_transactions" })}</p>
                    ) : (
                        inventory.transactions.map((transaction: MergedTransaction) => {
                            if (!transaction) return null
                            const transactionStock = getPAStockTransactionUnitBalance(transaction, putawayDetails)
                            const disabled = isFetchingDetails || transactionStock.color === "muted"

                            return (
                                <div
                                    key={transaction?.merged_id}
                                    className={cn(
                                        "flex justify-between rounded bg-primary p-2 transition-colors",
                                        (isFetchingDetails || transactionStock.color === "muted") && "bg-muted",
                                        !disabled && `cursor-pointer hover:bg-primary/50`,
                                        transactionStock.color === "success" && "bg-success hover:bg-success/50",
                                        transactionStock.color === "warning" && "bg-warning hover:bg-warning/50",
                                        transactionStock.color === "destructive" && "bg-destructive hover:bg-destructive/50"
                                    )}
                                    onClick={() => {
                                        if (!isFetchingDetails && !disabled) {
                                            mutatePutawayState({
                                                selectedInventory: inventory,
                                                selectedTransaction: transaction,
                                            })
                                        }
                                    }}
                                >
                                    <div className="flex flex-row items-center gap-2">
                                        <p
                                            className={cn(
                                                `text-sm font-bold text-primary-foreground`,
                                                isFetchingDetails && "text-muted-foreground",
                                                transactionStock.color === "success" && "text-success-foreground",
                                                transactionStock.color === "warning" && "text-warning-foreground",
                                                transactionStock.color === "destructive" && "text-destructive-foreground",
                                                transactionStock.color === "muted" && "text-muted-foreground"
                                            )}
                                        >
                                            {transaction?.document_header?.doc_code}
                                        </p>
                                        {transaction?.document_header?.is_rfid && <Icons.rfid className="h-6 w-6" />}
                                    </div>
                                    <p
                                        className={cn(
                                            `text-sm font-bold text-primary-foreground`,
                                            isFetchingDetails && "text-muted-foreground",
                                            transactionStock.color === "success" && "text-success-foreground",
                                            transactionStock.color === "warning" && "text-warning-foreground",
                                            transactionStock.color === "destructive" && "text-destructive-foreground",
                                            transactionStock.color === "muted" && "text-muted-foreground"
                                        )}
                                    >
                                        {renderUomQuantities(transactionStock.balance, inventory.item?.uoms_descending)}
                                    </p>
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default InventoryNode
