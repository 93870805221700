"use client"

import * as React from "react"
import { Drawer as DrawerPrimitive } from "vaul"

import { cn } from "@/lib/utils"

const Drawer = (props: React.ComponentProps<typeof DrawerPrimitive.Root>) => <DrawerPrimitive.Root {...props} />
Drawer.displayName = "Drawer"

const DrawerTrigger = DrawerPrimitive.Trigger

const DrawerPortal = DrawerPrimitive.Portal

const DrawerClose = DrawerPrimitive.Close

const DrawerOverlay = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>>(
    ({ className, ...props }, ref) => <DrawerPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-black/80", className)} {...props} />
)
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName

export enum DrawerPositionEnum {
    TOP = "top",
    RIGHT = "right",
    BOTTOM = "bottom",
    LEFT = "left",
}

interface DrawerContentProps extends React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> {
    hideHandle?: boolean
    hideOverlay?: boolean
    position?: DrawerPositionEnum
}

const DrawerContent = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Content>, DrawerContentProps>(
    ({ className, hideHandle, hideOverlay, position, children, ...props }, ref) => (
        <DrawerPortal>
            {!!!hideOverlay && <DrawerOverlay />}
            <DrawerPrimitive.Content
                ref={ref}
                className={cn(
                    "fixed bottom-0 z-50 flex h-auto flex-col items-center gap-4 border bg-background p-6",
                    position === DrawerPositionEnum.LEFT && "left-0 flex-row-reverse items-center",
                    position === DrawerPositionEnum.RIGHT && "right-0 flex-row",
                    position === DrawerPositionEnum.TOP && "bottom-auto top-0",
                    className
                )}
                {...props}
            >
                {!!!hideHandle && (
                    <div
                        className={cn(
                            "h-2 w-[100px] rounded-full bg-muted",
                            (position === DrawerPositionEnum.LEFT || position === DrawerPositionEnum.RIGHT) && "h-[100px] w-2"
                        )}
                    />
                )}
                <div className="flex h-full w-full flex-col justify-between">{children}</div>
            </DrawerPrimitive.Content>
        </DrawerPortal>
    )
)
DrawerContent.displayName = "DrawerContent"

const DrawerHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)} {...props} />
)
DrawerHeader.displayName = "DrawerHeader"

const DrawerFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => <div className={cn("mt-auto flex flex-col gap-2 py-4", className)} {...props} />
DrawerFooter.displayName = "DrawerFooter"

const DrawerTitle = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>>(
    ({ className, ...props }, ref) => <DrawerPrimitive.Title ref={ref} className={cn("text-lg font-semibold leading-none tracking-tight", className)} {...props} />
)
DrawerTitle.displayName = DrawerPrimitive.Title.displayName

const DrawerDescription = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Description>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>>(
    ({ className, ...props }, ref) => <DrawerPrimitive.Description ref={ref} className={cn("text-sm text-muted-foreground", className)} {...props} />
)
DrawerDescription.displayName = DrawerPrimitive.Description.displayName

export { Drawer, DrawerPortal, DrawerOverlay, DrawerTrigger, DrawerClose, DrawerContent, DrawerHeader, DrawerFooter, DrawerTitle, DrawerDescription }
