import { GeneralStatusEnum } from "./GeneralStatus"

export enum PutawayStatusEnum {
    DRAFT = "draft",
    PENDING = "pending",
    VOID = "void",
    IN_PROGRESS = "in_progress",
    PARTIAL_COMPLETED = "partial_completed",
    COMPLETED = "completed",
}

export const PutawayStatusLabel = {
    [GeneralStatusEnum.DRAFT]: "Draft",
    [GeneralStatusEnum.PENDING]: "Pending",
    [GeneralStatusEnum.VOID]: "Void",
    [GeneralStatusEnum.IN_PROGRESS]: "In Progress",
    [GeneralStatusEnum.PARTIAL_COMPLETED]: "Partial Completed",
    [GeneralStatusEnum.COMPLETED]: "Completed",
}

export const PutawayStatusSequence = [
    {
        status: GeneralStatusEnum.DRAFT,
        optional: false,
    },
    {
        status: GeneralStatusEnum.PENDING,
        optional: false,
    },
    {
        status: GeneralStatusEnum.IN_PROGRESS,
        optional: false,
    },
    {
        status: GeneralStatusEnum.COMPLETED,
        optional: false,
    },
]

export const PutawayStatusOptions = [
    { value: GeneralStatusEnum.DRAFT, label: GeneralStatusEnum.DRAFT },
    { value: GeneralStatusEnum.PENDING, label: GeneralStatusEnum.PENDING },
    { value: GeneralStatusEnum.VOID, label: GeneralStatusEnum.VOID },
    { value: GeneralStatusEnum.IN_PROGRESS, label: GeneralStatusEnum.IN_PROGRESS },
    { value: GeneralStatusEnum.PARTIAL_COMPLETED, label: GeneralStatusEnum.PARTIAL_COMPLETED },
    { value: GeneralStatusEnum.COMPLETED, label: GeneralStatusEnum.COMPLETED },
]

export const PutawayStatusSelectOptions = [
    { value: GeneralStatusEnum.DRAFT, label: PutawayStatusLabel[GeneralStatusEnum.DRAFT] },
    { value: GeneralStatusEnum.PENDING, label: PutawayStatusLabel[GeneralStatusEnum.PENDING] },
    { value: GeneralStatusEnum.VOID, label: PutawayStatusLabel[GeneralStatusEnum.VOID] },
    { value: GeneralStatusEnum.IN_PROGRESS, label: PutawayStatusLabel[GeneralStatusEnum.IN_PROGRESS] },
    { value: GeneralStatusEnum.PARTIAL_COMPLETED, label: PutawayStatusLabel[GeneralStatusEnum.PARTIAL_COMPLETED] },
    { value: GeneralStatusEnum.COMPLETED, label: PutawayStatusLabel[GeneralStatusEnum.COMPLETED] },
]

export const isPutawayEditable = (status?: GeneralStatusEnum, pathname?: string) =>
    (status === GeneralStatusEnum.DRAFT || status === GeneralStatusEnum.PENDING) && (!pathname || pathname?.includes("update"))

export const isPutawayStatusEditable = (status?: GeneralStatusEnum, pathname?: string) => status === GeneralStatusEnum.VOID && (!pathname || pathname?.includes("update"))

export const statusValidation: {
    [section: string]: {
        [status: string]: boolean
    }
} = {
    [GeneralStatusEnum.DRAFT]: {
        [GeneralStatusEnum.PENDING]: false,
        [GeneralStatusEnum.IN_PROGRESS]: true,
        [GeneralStatusEnum.VOID]: false,
        [GeneralStatusEnum.PARTIAL_COMPLETED]: true,
        [GeneralStatusEnum.COMPLETED]: true,
    },
    [GeneralStatusEnum.PENDING]: {
        [GeneralStatusEnum.DRAFT]: false,
        [GeneralStatusEnum.IN_PROGRESS]: true,
        [GeneralStatusEnum.VOID]: false,
        [GeneralStatusEnum.PARTIAL_COMPLETED]: true,
        [GeneralStatusEnum.COMPLETED]: true,
    },
    [GeneralStatusEnum.IN_PROGRESS]: {
        [GeneralStatusEnum.DRAFT]: true,
        [GeneralStatusEnum.PENDING]: true,
        [GeneralStatusEnum.VOID]: false,
        [GeneralStatusEnum.PARTIAL_COMPLETED]: false,
        [GeneralStatusEnum.COMPLETED]: false,
    },
    [GeneralStatusEnum.VOID]: {
        [GeneralStatusEnum.DRAFT]: false,
        [GeneralStatusEnum.PENDING]: true,
        [GeneralStatusEnum.IN_PROGRESS]: true,
        [GeneralStatusEnum.PARTIAL_COMPLETED]: true,
        [GeneralStatusEnum.COMPLETED]: true,
    },
    [GeneralStatusEnum.PARTIAL_COMPLETED]: {
        [GeneralStatusEnum.DRAFT]: true,
        [GeneralStatusEnum.PENDING]: true,
        [GeneralStatusEnum.IN_PROGRESS]: true,
        [GeneralStatusEnum.VOID]: true,
        [GeneralStatusEnum.COMPLETED]: false,
    },
    [GeneralStatusEnum.COMPLETED]: {
        [GeneralStatusEnum.DRAFT]: true,
        [GeneralStatusEnum.PENDING]: true,
        [GeneralStatusEnum.IN_PROGRESS]: true,
        [GeneralStatusEnum.VOID]: true,
        [GeneralStatusEnum.PARTIAL_COMPLETED]: true,
    },
}
