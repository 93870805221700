import FormMessageWrapper from "@/components/forms/form-message-wrapper"
import { Button } from "@/components/ui/button"
import { Command, CommandEmpty, CommandGroup, CommandItem } from "@/components/ui/command"
import { FormField, FormItem, FormLabel } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import { Item } from "@/types/Item"
import { Uom } from "@/types/Uom"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { CheckIcon, Search } from "lucide-react"
import React, { ReactElement, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

interface Props {
    selectedItem?: Item
    isSubmitting: boolean
    readonly?: boolean
}

const UomField: React.FC<Props> = ({ selectedItem, isSubmitting, readonly }): ReactElement => {
    const intl = useIntl()
    const form = useFormContext()
    const qty = form.watch("qty")
    const currentUom = form.watch("uom")

    const [search, setSearch] = useState<string>()

    const uoms = (selectedItem?.uoms ?? []).filter((uom) => uom.code.toLowerCase().includes(search?.toLowerCase() ?? ""))

    return (
        <FormField
            control={form.control}
            name="uom"
            render={({ field }) => (
                <FormItem className="col-span-1 flex flex-col gap-1 pt-1.5">
                    <FormLabel>{intl.formatMessage({ id: "uom" })}</FormLabel>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                className={cn("justify-between", !field.value && "text-muted-foreground")}
                                disabled={!!!selectedItem || isSubmitting || readonly}
                            >
                                <p className="truncate">{field.value ? field.value.label : `${intl.formatMessage({ id: "select" })} ${intl.formatMessage({ id: "uom" })}`}</p>
                                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="p-0">
                            <Command>
                                <div className="p-2">
                                    <Input
                                        prefix={<Search className="h-4 w-4 text-muted-foreground" />}
                                        placeholder={`${intl.formatMessage({ id: "search" })} ${intl.formatMessage({ id: "uom" })}`}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                    />
                                </div>
                                <CommandEmpty>{intl.formatMessage({ id: "no_results" })}</CommandEmpty>
                                <CommandGroup>
                                    <ScrollArea className={uoms.length > 8 ? "h-72" : ""}>
                                        {uoms.map((uom: Uom) => (
                                            <CommandItem
                                                value={String(uom.id)}
                                                key={`uom-${uom.id}`}
                                                onSelect={async () => {
                                                    let newQty = qty * Number(currentUom.pivot?.uom_rate ?? 1)
                                                    newQty /= Number(uom.pivot?.uom_rate ?? 1)
                                                    form.setValue("qty", newQty)

                                                    field.onChange({
                                                        label: uom.code,
                                                        value: uom.id,
                                                        allow_decimal: !!uom.allow_decimal,
                                                        pivot: uom.pivot,
                                                    })
                                                    await form.trigger("uom")
                                                }}
                                            >
                                                <CheckIcon className={cn("mr-2 h-4 w-4", uom.id === field.value?.value ? "opacity-100" : "opacity-0")} />
                                                {uom.code}
                                            </CommandItem>
                                        ))}
                                    </ScrollArea>
                                </CommandGroup>
                            </Command>
                        </PopoverContent>
                    </Popover>
                    <FormMessageWrapper fieldName="uom" />
                </FormItem>
            )}
        />
    )
}

export default UomField
