import useFetchStorageBins from "@/api/storage-bin/useFetchStorageBins"
import useSetLocationLayout from "@/api/location/useSetLocationLayout"
import useLocationLayout from "@/api/location/useLocationLayout"
import { Button } from "@/components/ui/button"
import { Command, CommandEmpty, CommandGroup, CommandItem } from "@/components/ui/command"
import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import Loader2 from "@/components/ui/loader2"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import { StorageBin } from "@/types/StorageBin"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { debounce } from "lodash"
import { CheckIcon, Map, Search } from "lucide-react"
import React, { ReactElement, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

interface Props {
    isSubmitting: boolean
    readonly?: boolean
    item_id?: number
}

const AssignedStorageBinField: React.FC<Props> = ({ isSubmitting, readonly, item_id }): ReactElement => {
    const intl = useIntl()
    const form = useFormContext()

    const assigned_loc = form.watch("assigned_loc")

    const [search, setSearch] = useState<string>()

    const {
        data: storageBinsData,
        isFetching,
        refetch,
    } = useFetchStorageBins({
        enableHook: !!assigned_loc,
        param: {
            ...(search && {
                keywords: search,
            }),
            location_id: String(assigned_loc?.value ?? "0"),
        },
    })
    const storageBins = Array.isArray(storageBinsData?.data) ? storageBinsData.data : []
    const { mutate: mutateLocationLayout } = useSetLocationLayout()
    const { data: locationLayout } = useLocationLayout()

    const debouncedSearch = debounce((value: string) => {
        setSearch(value)
        refetch()
    }, 500)

    const onSelectStorageBin = async (storageBin: StorageBin) => {
        form.setValue("assigned_sb", {
            label: storageBin.name,
            value: storageBin.id,
        })
        await form.trigger("assigned_sb")
        mutateLocationLayout({
            selectedStorageBinId: storageBin.id,
            item_id: item_id,
        })
    }

    return (
        <div className="col-span-2 flex items-end justify-between gap-2">
            <FormField
                control={form.control}
                name="assigned_sb"
                render={({ field }) => (
                    <FormItem className="flex w-full flex-col">
                        <FormLabel>{intl.formatMessage({ id: "assigned_storage_bin" })}</FormLabel>
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    className={cn("justify-between", !field.value && "text-muted-foreground")}
                                    disabled={!!!assigned_loc || isSubmitting || readonly}
                                >
                                    <p className="truncate">
                                        {field.value ? field.value.label : `${intl.formatMessage({ id: "select" })} ${intl.formatMessage({ id: "storage_bin" })}`}
                                    </p>
                                    <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="p-0">
                                <Command>
                                    <div className="p-2">
                                        <Input
                                            prefix={<Search className="h-4 w-4 text-muted-foreground" />}
                                            placeholder={`${intl.formatMessage({ id: "search" })} ${intl.formatMessage({ id: "storage_bin" })}`}
                                            onChange={(e) => {
                                                debouncedSearch(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <CommandEmpty className="flex justify-center py-2">{isFetching ? <Loader2 /> : intl.formatMessage({ id: "no_results" })}</CommandEmpty>
                                    <CommandGroup>
                                        <ScrollArea className={storageBins.length > 8 ? "h-72" : ""}>
                                            {storageBins.map((storageBin: StorageBin) => (
                                                <CommandItem
                                                    value={String(storageBin.id)}
                                                    key={`storage_bin-${storageBin.id}`}
                                                    onSelect={async () => {
                                                        onSelectStorageBin(storageBin)
                                                    }}
                                                >
                                                    <CheckIcon className={cn("mr-2 h-4 w-4", storageBin.id === field.value?.value ? "opacity-100" : "opacity-0")} />
                                                    {storageBin.name}
                                                </CommandItem>
                                            ))}
                                        </ScrollArea>
                                    </CommandGroup>
                                </Command>
                            </PopoverContent>
                        </Popover>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <Button
                type="button"
                className="flex justify-center gap-2"
                disabled={!!!assigned_loc}
                onClick={() => {
                    if (locationLayout.isOpen) {
                        mutateLocationLayout({
                            isOpen: false,
                            item_id: item_id,
                        })
                    } else {
                        mutateLocationLayout({
                            isOpen: true,
                            selectableStorageBinFn: onSelectStorageBin,
                            item_id: item_id,
                        })
                    }
                }}
            >
                <Map />
                {locationLayout.isOpen ? intl.formatMessage({ id: "hide_layout" }) : intl.formatMessage({ id: "view_layout" })}
            </Button>
        </div>
    )
}

export default AssignedStorageBinField
