import { z } from "zod"
import { Uom } from "./Uom"
import { BaseModel } from "."

export interface ItemUom extends Uom {
    item_id?: number
    uom_id: number
    uom_rate: number
    rate: number
    barcode: string
    pivot: PivotItemUom
}

export interface PivotItemUom {
    barcode: string
    created_at: string
    is_default: boolean
    item_id: number
    uom_id: number
    uom_rate: string
    updated_at: string
}

export interface BaseItemUom extends BaseModel {
    item_id: number
    uom_id: number
    uom_rate: number
    barcode: string
    is_default: boolean

    uom?: Uom
}

export const PivotItemUomSchema = z.object({
    barcode: z.string().nullable(),
    created_at: z.string(),
    is_default: z.number().transform((value) => value === 1),
    item_id: z.number(),
    uom_id: z.number(),
    uom_rate: z.string(),
    updated_at: z.string(),
})
