import { useQuery } from "@tanstack/react-query"
import InitialStatusLogState from "./InitialStatusLogState"
import { StatusLogState } from "@/types/StatusLog"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return useQuery<StatusLogState>({
        queryKey: ["status-log-state"],
        initialData: InitialStatusLogState,
    })
}
