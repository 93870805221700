import { useQuery } from "@tanstack/react-query"
import ApiService, { QueryProps } from "@/lib/ApiService"
import { useSession } from "next-auth/react"

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ enableHook = true, param }: QueryProps = {}) => {
    const { data: session, status } = useSession()

    const queryParams = ApiService.toQueryString(param)

    return useQuery({
        queryKey: ["fetch-storage-bins", param],
        queryFn: async () => {
            try {
                if (status === "authenticated") {
                    const result = await ApiService.getApi(`storage-bin?${queryParams}`, session!.token)

                    if (result.isSuccess) {
                        return result.data
                    }
                }

                return {
                    isSuccess: false,
                    message: "Unauthorized",
                }
            } catch (error) {
                return {
                    isSuccess: false,
                    message: (error as Error).message,
                }
            }
        },
        enabled: enableHook && status === "authenticated",
        refetchOnWindowFocus: "always",
    })
}
