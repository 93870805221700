import { useQuery } from "@tanstack/react-query"
import { SalesOrderState } from "@/types/SalesOrder"
import InitialSalesOrderState from "./InitialSalesOrderState"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return useQuery<SalesOrderState>({
        queryKey: ["sales-order-state"],
        initialData: InitialSalesOrderState,
    })
}
