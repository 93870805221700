import { SalesOrderState } from "@/types/SalesOrder"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import InitialSalesOrderState from "./InitialSalesOrderState"

export default () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (values: Partial<SalesOrderState>) => {
            const SalesOrder = queryClient.getQueryData<SalesOrderState>(["sales-order-state"])
            return {
                ...InitialSalesOrderState,
                ...SalesOrder,
                ...values,
            }
        },
        onSuccess: (data) => {
            queryClient.setQueryData<SalesOrderState>(["sales-order-state"], data)
        },
    })
}
