import { useMutation } from "@tanstack/react-query"

import ApiService from "@/lib/ApiService"
import { useSession } from "next-auth/react"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { data: session, status } = useSession()

    return useMutation({
        mutationFn: async (values: any) => {
            try {
                if (status === "authenticated") {
                    const result = await ApiService.postApi(`putaway/${values.putaway_id}/details`, session!.token, values)

                    return result
                } else {
                    return {
                        isSuccess: false,
                        message: "Unauthorized",
                    }
                }
            } catch (error) {
                throw error
            }
        },
    })
}
