import { useQuery } from "@tanstack/react-query"
import InitialState, { AlertDialogState } from "./InitialState"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return useQuery<AlertDialogState>({
        queryKey: ["alert-dialog"],
        initialData: InitialState,
    })
}
