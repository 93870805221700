import useSalesOrderState from "@/api/sales-order/useSalesOrderState"
import useSetSalesOrderState from "@/api/sales-order/useSetSalesOrderState"
import { Separator } from "@/components/ui/separator"
import { getItemVariantName } from "@/lib/MapHelper"
import { cn } from "@/lib/utils"
import { BaseItemUom } from "@/types/ItemUom"
import { SalesOrderDetail } from "@/types/SalesOrderDetail"
import Decimal from "decimal.js"
import React, { ReactElement } from "react"
import { useIntl } from "react-intl"

interface Props {
    index: number
    detail: SalesOrderDetail
    isSplitDetail?: boolean
    lines: number
}

const DetailNode: React.FC<Props> = ({ index, detail, isSplitDetail, lines }): ReactElement => {
    const intl = useIntl()

    const {
        data: { isSplitMode, selectedSplitItems },
    } = useSalesOrderState()
    const { mutate } = useSetSalesOrderState()

    const price = new Decimal(detail.item_price)
    const totalPrice = new Decimal(detail.total_price)
    const isSelectable = isSplitMode && !detail.is_sufficient && !isSplitDetail

    const handleSelectSplitItems = () => {
        if (isSelectable && !isSplitDetail) {
            const isExist = selectedSplitItems.find((item) => item.id === detail.id)
            if (isExist) {
                mutate({
                    selectedSplitItems: selectedSplitItems.filter((item) => item.id !== detail.id),
                })
            } else {
                mutate({
                    selectedSplitItems: [...selectedSplitItems, detail],
                })
            }
        }
    }

    return (
        <React.Fragment>
            {index > 0 && index < lines && <Separator />}
            <div
                className={cn(
                    "flex flex-col gap-2 rounded bg-muted p-4",
                    isSelectable && "cursor-pointer hover:bg-muted/75",
                    !!selectedSplitItems.find((item) => detail.id === item.id) && "border-2 border-success"
                )}
                onClick={handleSelectSplitItems}
            >
                <div className="flex items-center justify-between">
                    <div>
                        <p className="truncate font-bold">{detail.item_code}</p>
                        <p className="truncate text-sm text-muted-foreground">{`${getItemVariantName(detail.var_combo)} • ${detail.item_barcode}`}</p>
                    </div>
                    <div className="flex flex-row gap-2">
                        <div className="rounded-md bg-slate-400 bg-opacity-25 px-2 py-1 text-right">
                            <p className="text-muted-foreground">{intl.formatMessage({ id: "quantity" })}</p>
                            <p
                                className={cn("font-bold", detail.is_sufficient ? "text-success" : !isSplitDetail && "text-destructive")}
                            >{`${detail.item_quantity} ${(detail.item_uom as BaseItemUom)?.uom?.code}`}</p>
                        </div>
                        <div className="rounded-md bg-slate-400 bg-opacity-25 px-2 py-1 text-right">
                            <p className="text-muted-foreground">{intl.formatMessage({ id: "unfulfilled" })}</p>
                            <p className="font-bold text-warning">{`${(detail.item_quantity - detail.picked_quantity).toFixed(2)} ${(detail.item_uom as BaseItemUom)?.uom?.code}`}</p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <p>
                        <span className="text-xl font-bold">{`RM ${price.toFixed(2)}`}</span>
                        &nbsp;
                        <span className="text-md text-muted-foreground">{`per ${(detail.item_uom as BaseItemUom)?.uom?.code}`}</span>
                    </p>
                    <p className="text-xl font-bold">{`RM ${totalPrice.toFixed(2)}`}</p>
                </div>
                {!isSplitDetail &&
                    (!!!detail.item ? (
                        <p className="text-destructive">{intl.formatMessage({ id: "error_missing_sales_order_item" })}</p>
                    ) : (
                        !!!detail.is_sufficient && <p className="text-destructive">{intl.formatMessage({ id: "error_insufficient_sales_order_item" })}</p>
                    ))}
            </div>
        </React.Fragment>
    )
}

export default DetailNode
