import { GeneralStatusEnum } from "./GeneralStatus"

export type DeliveryNoteStatus = GeneralStatusEnum.PENDING | GeneralStatusEnum.VOID | GeneralStatusEnum.IN_PROGRESS | GeneralStatusEnum.COMPLETED

export const DeliveryNoteStatusLabel = {
    [GeneralStatusEnum.PENDING]: "Pending",
    [GeneralStatusEnum.VOID]: "Void",
    [GeneralStatusEnum.IN_PROGRESS]: "In Progress",
    [GeneralStatusEnum.COMPLETED]: "Completed",
}

export const DeliveryNoteStatusSequence = [
    {
        status: GeneralStatusEnum.PENDING,
        optional: false,
    },
    {
        status: GeneralStatusEnum.IN_PROGRESS,
        optional: false,
    },
    {
        status: GeneralStatusEnum.COMPLETED,
        optional: false,
    },
]

export const DeliveryNoteStatusOptions = [
    { value: GeneralStatusEnum.PENDING, label: GeneralStatusEnum.PENDING },
    { value: GeneralStatusEnum.VOID, label: GeneralStatusEnum.VOID },
    { value: GeneralStatusEnum.IN_PROGRESS, label: GeneralStatusEnum.IN_PROGRESS },
    { value: GeneralStatusEnum.COMPLETED, label: GeneralStatusEnum.COMPLETED },
]

export const DeliveryNoteStatusSelectOptions = [
    { value: GeneralStatusEnum.PENDING, label: DeliveryNoteStatusLabel[GeneralStatusEnum.PENDING] },
    { value: GeneralStatusEnum.VOID, label: DeliveryNoteStatusLabel[GeneralStatusEnum.VOID] },
    { value: GeneralStatusEnum.IN_PROGRESS, label: DeliveryNoteStatusLabel[GeneralStatusEnum.IN_PROGRESS] },
    { value: GeneralStatusEnum.COMPLETED, label: DeliveryNoteStatusLabel[GeneralStatusEnum.COMPLETED] },
]
