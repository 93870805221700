import { ButtonProps } from "@/components/ui/button"

export interface AlertDialogState {
    isOpen: boolean
    title: string
    content: React.ReactNode | string | null
    okVariant: ButtonProps["variant"]

    onOk: () => void
    onCancel: () => void
}

const InitialState: AlertDialogState = {
    isOpen: false,
    title: "",
    content: null,
    okVariant: "default",

    onOk: () => {},
    onCancel: () => {},
}

export default InitialState
