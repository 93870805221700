import { GeneralStatusEnum } from "./GeneralStatus"

export const GoodsReceivedStatusLabel = {
    [GeneralStatusEnum.DRAFT]: "Draft",
    [GeneralStatusEnum.PENDING]: "Pending",
    [GeneralStatusEnum.VOID]: "Void",
    [GeneralStatusEnum.IN_PROGRESS]: "In Progress",
    [GeneralStatusEnum.PENDING_TAG]: "Pending Tagging",
    [GeneralStatusEnum.COMPLETED]: "Completed",
}

export const GoodsReceivedStatusSequence = [
    {
        status: GeneralStatusEnum.DRAFT,
        optional: false,
    },
    {
        status: GeneralStatusEnum.PENDING,
        optional: false,
    },
    {
        status: GeneralStatusEnum.IN_PROGRESS,
        optional: false,
    },
    {
        status: GeneralStatusEnum.PENDING_TAG,
        optional: false,
    },
    {
        status: GeneralStatusEnum.COMPLETED,
        optional: false,
    },
]

export const GoodsReceivedStatusOptions = [
    { value: GeneralStatusEnum.DRAFT, label: GeneralStatusEnum.DRAFT },
    { value: GeneralStatusEnum.VOID, label: GeneralStatusEnum.VOID },
    { value: GeneralStatusEnum.PENDING_TAG, label: GeneralStatusEnum.PENDING_TAG },
    { value: GeneralStatusEnum.COMPLETED, label: GeneralStatusEnum.COMPLETED },
]

export const GoodsReceivedStatusSelectOptions = [
    { value: GeneralStatusEnum.DRAFT, label: GoodsReceivedStatusLabel[GeneralStatusEnum.DRAFT] },
    { value: GeneralStatusEnum.VOID, label: GoodsReceivedStatusLabel[GeneralStatusEnum.VOID] },
    { value: GeneralStatusEnum.PENDING_TAG, label: GoodsReceivedStatusLabel[GeneralStatusEnum.PENDING_TAG] },
    { value: GeneralStatusEnum.COMPLETED, label: GoodsReceivedStatusLabel[GeneralStatusEnum.COMPLETED] },
]
