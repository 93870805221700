import { StatusLogState } from "@/types/StatusLog"

const InitialStatusLogState: StatusLogState = {
    open: false,
    originalDocument: undefined,
    data: [],
    statusType: undefined,
}

export default InitialStatusLogState
