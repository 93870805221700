import useLocationLayout from "@/api/location/useLocationLayout"
import PutawaySelectItemDrawer from "@/components/drawers/putaway/select-item"
import SalesOrderSelectItemDrawer from "@/components/drawers/sales-order/select-item"
import PutawaySelectItemFormDrawer from "@/components/drawers/putaway/select-item-form"
import { Drawer, DrawerContent, DrawerPositionEnum } from "@/components/ui/drawer"
import LocationLayout from "@/components/location-layout"
import React, { PropsWithChildren, ReactElement } from "react"
import { useIntl } from "react-intl"
import { usePathname } from "next/navigation"
import StatusLogSheet from "@/components/sheet/status-log"

const DrawerProvider: React.FC<PropsWithChildren> = ({ children }): ReactElement => {
    const intl = useIntl()
    const pathname = usePathname()

    const isPutawayDrawers = pathname.includes("/putaway")

    const { data: locationLayout } = useLocationLayout()

    return (
        <React.Fragment>
            {children}
            {isPutawayDrawers && (
                <React.Fragment>
                    <PutawaySelectItemDrawer />
                    <PutawaySelectItemFormDrawer />
                </React.Fragment>
            )}
            <SalesOrderSelectItemDrawer />
            <Drawer modal={false} dismissible={false} direction="right" open={!!locationLayout?.isOpen}>
                <DrawerContent hideHandle position={DrawerPositionEnum.RIGHT} className="h-full">
                    <LocationLayout title={intl.formatMessage({ id: "location_layout" })} locationId={String(locationLayout.selectedId)} />
                </DrawerContent>
            </Drawer>
            <StatusLogSheet />
        </React.Fragment>
    )
}

export default DrawerProvider
