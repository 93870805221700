"use client"

import { QueryClientProvider } from "@tanstack/react-query"
import ThemeProvider from "@/components/layout/ThemeToggle/theme-provider"
import { SessionProvider, SessionProviderProps } from "next-auth/react"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { queryClient } from "./queryClient"
import { IntlProvider } from "react-intl"
import languageObject from "@/translations"
import DrawerProvider from "./drawer-provider"
import AlertDialogProvider from "./AlertDialogProvider"

export default function ClientProvider({ children, session }: { children: React.ReactNode; session: SessionProviderProps["session"] }): React.ReactNode {
    return (
        <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
            <IntlProvider locale={"en-US"} messages={languageObject["en-US"]}>
                <QueryClientProvider client={queryClient}>
                    <SessionProvider session={session}>
                        <AlertDialogProvider>
                            <DrawerProvider>{children}</DrawerProvider>
                        </AlertDialogProvider>
                    </SessionProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </IntlProvider>
        </ThemeProvider>
    )
}
