import { StatusLogState } from "@/types/StatusLog"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import InitialStatusLogState from "./InitialStatusLogState"

export default () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (values: Partial<StatusLogState>) => {
            return values
        },
        onSuccess: (data) => {
            const StatusLog = queryClient.getQueryData<StatusLogState>(["status-log-state"])
            queryClient.setQueryData<StatusLogState>(["status-log-state"], {
                ...InitialStatusLogState,
                ...StatusLog,
                ...data,
            })
        },
    })
}
