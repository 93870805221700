import { useInfiniteQuery } from "@tanstack/react-query"
import ApiService, { PaginatedResult, QueryProps } from "@/lib/ApiService"
import { useSession } from "next-auth/react"
import { MergedInventory } from "@/types/Inventory"

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ enableHook = true, param }: QueryProps = {}) => {
    const { data: session, status } = useSession()

    const queryParams = ApiService.toQueryString(param)

    return useInfiniteQuery<PaginatedResult<MergedInventory>>({
        queryKey: ["fetch-grouped-inventory-transactions", param],
        queryFn: async ({ pageParam }) => {
            try {
                if (status === "authenticated") {
                    const result = await ApiService.getApi(`grouped-inventory/transactions?page=${pageParam}&${queryParams}`, session!.token)

                    if (result.isSuccess) {
                        return result.data
                    }
                }

                return {
                    isSuccess: false,
                    message: "Unauthorized",
                }
            } catch (error) {
                return {
                    isSuccess: false,
                    message: (error as Error).message,
                }
            }
        },
        initialPageParam: 1,
        enabled: enableHook && status === "authenticated",
        refetchOnWindowFocus: "always",
        getNextPageParam: ({ current_page, last_page }) => (last_page === current_page ? undefined : current_page + 1),
    })
}
