import { PutawayState } from "@/types/Putaway"

const InitialPutawayState: PutawayState = {
    openAddItemDrawer: false,
    selectedLocation: undefined,
    selectedInventory: undefined,
    selectedTransaction: undefined,
    putawayId: undefined,
    putawayDetail: {
        data: undefined,
        editMode: false,
    },
}

export default InitialPutawayState
