"use client"

import { cn } from "@/lib/utils"
import { AlertCircle } from "lucide-react"
import { useTheme } from "next-themes"
import { Toaster as Sonner } from "sonner"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
    const { theme = "system" } = useTheme()

    return (
        <Sonner
            theme={theme as ToasterProps["theme"]}
            className="toaster group"
            toastOptions={{
                classNames: {
                    toast: "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
                    description: "group-[.toast]:text-muted-foreground",
                    actionButton: "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
                    cancelButton: "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
                },
            }}
            {...props}
        />
    )
}

export interface CustomToast {
    message: string
    description?: string
}

interface CustomToastContentProps extends CustomToast {
    success?: boolean
}

export const CustomToastContent = ({ success, message, description }: CustomToastContentProps) => {
    return (
        <div className="flex items-center space-x-2">
            <AlertCircle className={cn("h-4 w-4", success ? "text-green-500" : "text-red-500")} />
            <div>
                <p>{message}</p>
                <p className="text-muted-foreground">{description}</p>
            </div>
        </div>
    )
}

export { Toaster }
