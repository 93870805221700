import { PutawayState } from "@/types/Putaway"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import InitialPutawayState from "./InitialPutawayState"

export default () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (values: Partial<PutawayState>) => {
            const Putaway = queryClient.getQueryData<PutawayState>(["putaway-state"])
            return {
                ...InitialPutawayState,
                ...Putaway,
                ...values,
                putawayDetail: {
                    ...InitialPutawayState.putawayDetail,
                    ...Putaway?.putawayDetail,
                    ...values.putawayDetail,
                },
            }
        },
        onSuccess: (data) => {
            queryClient.setQueryData<PutawayState>(["putaway-state"], data)
        },
    })
}
