import { cn } from "@/lib/utils"
import { useIntl } from "react-intl"
import { Label } from "@/components/ui/label"
import { useFormContext } from "react-hook-form"
import React, { useEffect, useState } from "react"
import { Checkbox } from "@/components/ui/checkbox"

interface Serial {
    label: string
    value: {
        batch_no: string | null
        serial_no: string | null
        unit_qty: number
    }
}
interface Props {
    isLoading: boolean
    isSubmitting: boolean
    readonly?: boolean
    items: {
        label: string
        value: string | null
        serials: Serial[]
    }[]
}

const BatchSerialNoField: React.FC<Props> = ({ isLoading, isSubmitting, readonly, items }) => {
    const intl = useIntl()
    const form = useFormContext()

    const batchNosFormData = form.watch("batch_nos")
    const serialNosFormData = form.watch("serial_nos")
    const qty = form.watch("qty")
    const uom = form.watch("uom")

    const [unitQty, setUnitQty] = useState<number>(qty)

    const handleOnSerialChange = (item: any, value: any) => {
        let newSerialNos = serialNosFormData
        const isSelected = serialNosFormData?.find((serial: any) => serial.serial_no === value.serial_no && serial.batch_no === value.batch_no) ? true : false

        if (isSelected) {
            newSerialNos = serialNosFormData.filter((serial: any) => !(serial.serial_no == value.serial_no && serial.batch_no == value.batch_no))
            if (!item.serials.every((v: any) => newSerialNos?.includes(JSON.stringify(v)))) {
                form.setValue(
                    "batch_nos",
                    batchNosFormData.filter((batchNo: string) => batchNo !== item.value)
                )
            }
        } else {
            if (unitQty - serialNosFormData.length === 0) return
            newSerialNos = [...serialNosFormData, value]
            form.setValue("batch_nos", Array.from(new Set([...batchNosFormData, item.value])))
        }

        form.setValue("serial_nos", newSerialNos)
    }

    const handleOnSelectBatch = (item: any) => {
        const isBatchSelected = batchNosFormData?.includes(item.value)

        if (isBatchSelected) {
            const itemSerials = item.serials.map((v: Serial) => JSON.stringify(v.value))
            const newSerialNos = serialNosFormData.filter((item: any) => !itemSerials.includes(JSON.stringify(item)))

            form.setValue("serial_nos", newSerialNos)
            form.setValue(
                "batch_nos",
                batchNosFormData.filter((batchNo: string) => batchNo !== item.value)
            )
        } else {
            if (unitQty - serialNosFormData.length === 0) return
            const newSerialNos = [...serialNosFormData, ...item.serials.map((v: any) => v.value)]
            form.setValue("serial_nos", newSerialNos)
            form.setValue("batch_nos", Array.from(new Set([...batchNosFormData, item.value])))
        }
    }

    const isBatchChecked = (item: any) => {
        const isAllSelected = item.serials.every((v: Serial) => {
            return serialNosFormData?.find((serial: any) => serial.serial_no === v.value.serial_no && serial.batch_no === v.value.batch_no)
        })

        const isSomeSelected = item.serials.some((v: Serial) => {
            return serialNosFormData?.find((serial: any) => serial.serial_no === v.value.serial_no && serial.batch_no === v.value.batch_no)
        })

        if (isAllSelected) return true
        if (isSomeSelected) return "indeterminate"
        return false
    }

    useEffect(() => {
        form.setValue("batch_nos", [])
        form.setValue("serial_nos", [])

        const uom_rate = parseFloat(uom.pivot?.uom_rate ?? "1")
        const unit_qty = qty * uom_rate
        setUnitQty(unit_qty)
    }, [qty])

    return (
        <div className="col-span-2 flex w-full flex-col gap-3">
            <Label
                className={cn({
                    "text-red-500": form.formState.errors.batch_nos || form.formState.errors.serial_nos,
                })}
            >
                {intl.formatMessage({ id: "batch_serial_no" })}
            </Label>
            <div className="rounded-lg border p-3">
                {items.length > 0 &&
                    items.map((item: any, batchIndex: number) => {
                        return (
                            <React.Fragment key={item.value}>
                                <div key={item.value} className="flex items-center gap-2">
                                    <Checkbox
                                        id={`batch-${item.value}`}
                                        checked={isBatchChecked(item)}
                                        onCheckedChange={() => {
                                            handleOnSelectBatch(item)
                                        }}
                                        disabled={isLoading || isSubmitting || readonly}
                                    />
                                    <label htmlFor={`batch-${item.value}`}>{item.label ?? intl.formatMessage({ id: "no_batch_no" })}</label>
                                </div>

                                {item.serials.length > 0 &&
                                    item.serials.map((detail: any, serialIndex: number) => {
                                        const isSelected = serialNosFormData?.find((v: any) => v.serial_no === detail.value.serial_no && v.batch_no === detail.value.batch_no)
                                            ? true
                                            : false

                                        return (
                                            <div key={`${item.value}-${detail.value.serial_no}`} className="ml-6 flex items-center gap-2">
                                                <Checkbox
                                                    id={`batch-${item.value}-${detail.value.serial_no}`}
                                                    checked={isSelected}
                                                    onCheckedChange={() => {
                                                        handleOnSerialChange(item, detail.value)
                                                    }}
                                                    disabled={isLoading || isSubmitting || readonly}
                                                />
                                                <label htmlFor={`batch-${item.value}-${detail.value.serial_no}`}>{detail.label}</label>
                                            </div>
                                        )
                                    })}
                            </React.Fragment>
                        )
                    })}
            </div>
            {form.formState.errors.batch_nos && <span className="text-sm text-red-500">{String(form.formState.errors.batch_nos.message)}</span>}
            {form.formState.errors.serial_nos && <span className="text-sm text-red-500">{String(form.formState.errors.serial_nos.message)}</span>}
        </div>
    )
}

export default BatchSerialNoField
