import { GeneralStatusEnum } from "./GeneralStatus"

export type PickingStatus =
    | GeneralStatusEnum.PENDING
    | GeneralStatusEnum.VOID
    | GeneralStatusEnum.IN_PROGRESS
    | GeneralStatusEnum.COMPLETED
    | GeneralStatusEnum.PARTIALLY_COMPLETED
    | GeneralStatusEnum.ONHOLD

export const PickingStatusLabel = {
    [GeneralStatusEnum.PENDING]: "Pending",
    [GeneralStatusEnum.VOID]: "Void",
    [GeneralStatusEnum.IN_PROGRESS]: "In Progress",
    [GeneralStatusEnum.COMPLETED]: "Completed",
    [GeneralStatusEnum.PARTIALLY_COMPLETED]: "Partially Completed",
    [GeneralStatusEnum.ONHOLD]: "On Hold",
}

export const PickingStatusSequence = [
    {
        status: GeneralStatusEnum.PENDING,
        optional: false,
    },
    {
        status: GeneralStatusEnum.IN_PROGRESS,
        optional: false,
    },
    {
        status: GeneralStatusEnum.PARTIALLY_COMPLETED,
        optional: true,
    },
    {
        status: GeneralStatusEnum.COMPLETED,
        optional: false,
    },
]

export const PickingStatusOptions = [
    { value: GeneralStatusEnum.PENDING, label: GeneralStatusEnum.PENDING },
    { value: GeneralStatusEnum.VOID, label: GeneralStatusEnum.VOID },
    { value: GeneralStatusEnum.IN_PROGRESS, label: GeneralStatusEnum.IN_PROGRESS },
    { value: GeneralStatusEnum.COMPLETED, label: GeneralStatusEnum.COMPLETED },
    { value: GeneralStatusEnum.PARTIALLY_COMPLETED, label: GeneralStatusEnum.PARTIALLY_COMPLETED },
    { value: GeneralStatusEnum.ONHOLD, label: GeneralStatusEnum.ONHOLD },
]

export const PickingStatusSelectOptions = [
    { value: GeneralStatusEnum.PENDING, label: PickingStatusLabel[GeneralStatusEnum.PENDING] },
    { value: GeneralStatusEnum.VOID, label: PickingStatusLabel[GeneralStatusEnum.VOID] },
    { value: GeneralStatusEnum.IN_PROGRESS, label: PickingStatusLabel[GeneralStatusEnum.IN_PROGRESS] },
    { value: GeneralStatusEnum.COMPLETED, label: PickingStatusLabel[GeneralStatusEnum.COMPLETED] },
    { value: GeneralStatusEnum.PARTIALLY_COMPLETED, label: PickingStatusLabel[GeneralStatusEnum.PARTIALLY_COMPLETED] },
    { value: GeneralStatusEnum.ONHOLD, label: PickingStatusLabel[GeneralStatusEnum.ONHOLD] },
]
export const PickingStatusOnHoldSelectOptions = [
    { value: GeneralStatusEnum.VOID, label: PickingStatusLabel[GeneralStatusEnum.VOID] },
    { value: GeneralStatusEnum.ONHOLD, label: PickingStatusLabel[GeneralStatusEnum.ONHOLD] },
]
