import { LocationLayoutState } from "@/types/Location"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import InitialLocationLayoutState from "./InitialLocationLayoutState"

export default () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (values: Partial<LocationLayoutState>) => {
            const locationLayout = queryClient.getQueryData<LocationLayoutState>(["location-layout"])
            return {
                ...InitialLocationLayoutState,
                ...locationLayout,
                ...values,
            }
        },
        onSuccess: (data) => {
            queryClient.setQueryData<LocationLayoutState>(["location-layout"], data)
        },
    })
}
