"use client"

import { Button } from "@/components/ui/button"
import { Drawer, DrawerContent, DrawerFooter, DrawerPositionEnum, DrawerTitle } from "@/components/ui/drawer"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { ReactElement, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { z } from "zod"
import AssignedLocationField from "./fields/assigned-location-field"
import { Result } from "@/lib/ApiService"
import { ToastError, ToastSuccess } from "@/lib/toast"
import useCreatePutawayDetail from "@/api/putaway/useCreatePutawayDetail"
import { useParams } from "next/navigation"
import { Form } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import UomField from "./fields/uom-field"
import AssignedStorageBinField from "./fields/assigned-storage-bin-field"
import { cn } from "@/lib/utils"
import QuantityField from "./fields/quantity-field"
import Loader2 from "@/components/ui/loader2"
import { useQueryClient } from "@tanstack/react-query"
import { PivotItemUomSchema } from "@/types/ItemUom"
import useFetchPutawayDetails from "@/api/putaway/useFetchPutawayDetails"
import usePutawayState from "@/api/putaway/usePutawayState"
import useUpdatePutawayDetail from "@/api/putaway/useUpdatePutawayDetail"
import useSetPutawayState from "@/api/putaway/useSetPutawayState"
import useSetLocationLayout from "@/api/location/useSetLocationLayout"
import Decimal from "decimal.js"
import { ScrollArea } from "@/components/ui/scroll-area"
import BatchSerialNoField from "./fields/batch-serial-no-field"
import useFetchSelectedBatchSerialNos from "@/api/putaway/useFetchSelectedBatchSerialNos"
interface Serial {
    serial_no: string | null
    unit_qty: string
}

interface BatchSerial {
    [key: string]: Serial[]
}

const FieldLabel = ({ className, label, value }: { className?: string; label: string; value: string }) => (
    <div className={cn("flex flex-col gap-2", className)}>
        <Label>{label}</Label>
        <Input disabled value={value} />
    </div>
)

const SelectItemFormDrawer: React.FC = (): ReactElement | null => {
    const intl = useIntl()
    const { id } = useParams()
    const queryClient = useQueryClient()

    const [isSubmitting, setSubmitting] = useState<boolean>(false)

    const {
        data: {
            selectedInventory,
            selectedTransaction,
            putawayDetail: { data: selectedPutawayDetail, editMode },
        },
    } = usePutawayState()

    const FormSchema = z
        .object({
            id: z.number().optional(),
            putaway_id: z.number().optional(),
            item_id: z
                .number()
                .min(1, { message: intl.formatMessage({ id: "error_id_required" }) })
                .optional(),
            var_combo_id: z.number().optional(),
            assigned_loc: z
                .object({
                    label: z.string(),
                    value: z.string().or(z.number()),
                })
                .refine((data) => !!data, { message: intl.formatMessage({ id: "error_location_required" }) }),
            assigned_sb: z
                .object({
                    label: z.string(),
                    value: z.string().or(z.number()),
                })
                .refine((data) => !!data, { message: intl.formatMessage({ id: "error_storage_bin_required" }) }),
            uom: z
                .object({
                    label: z.string(),
                    value: z.string().or(z.number()),
                    allow_decimal: z.boolean().optional(),
                    pivot: PivotItemUomSchema.optional(),
                })
                .refine((data) => !!data, { message: intl.formatMessage({ id: "error_uom_required" }) }),
            qty: z.number().refine((data) => data > 0, { message: intl.formatMessage({ id: "error_qty_required" }) }),
            batch_nos: z.array(z.string().nullable()).optional(),
            serial_nos: z
                .array(
                    z
                        .object({
                            batch_no: z.string().nullable(),
                            serial_no: z.string().nullable(),
                            unit_qty: z.string(),
                        })
                        .nullable()
                )
                .optional(),
        })
        .refine(
            (data) => {
                const uom_rate = parseFloat(data.uom.pivot?.uom_rate ?? "1")
                const unit_qty = data.qty * uom_rate
                // Check if serial_nos length matches qty
                let serialCount = 0
                let isNullSerialSelected = false

                data.serial_nos?.forEach((serial) => {
                    if (serial?.serial_no === null) {
                        isNullSerialSelected = true
                    }
                    serialCount += serial ? parseFloat(serial.unit_qty) : 0
                })

                if (!isNullSerialSelected) {
                    return data.serial_nos && serialCount === unit_qty
                }

                return unit_qty <= serialCount
            },
            {
                message: intl.formatMessage({ id: "error_serial_nos_qty_mismatch" }),
                path: ["serial_nos"], // Path to indicate the location of the error
            }
        )

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            putaway_id: Number(id) ?? 0,
            item_id: Number(selectedInventory?.item.id) ?? 0,
            var_combo_id: Number(selectedInventory?.var_combo?.id) ?? 0,
            assigned_loc: undefined,
            assigned_sb: undefined,
            uom: undefined,
            qty: 0,
            batch_nos: [],
            serial_nos: [],
        },
    })
    const formValues = form.watch()
    const isEditMode = !!!selectedPutawayDetail || (selectedPutawayDetail && editMode)

    const { mutateAsync: createPutawayDetail, isPending: isPendingCreate } = useCreatePutawayDetail()
    const { mutateAsync: updatePutawayDetail, isPending: isPendingUpdate } = useUpdatePutawayDetail()
    const { isFetching: isFetchingDetails } = useFetchPutawayDetails({
        enableHook: !!id,
        id: Number(id),
    })
    const { data: selectedBatchSerialNosData } = useFetchSelectedBatchSerialNos({
        enableHook: !!selectedInventory,
        id: Number(id),
        param: {
            item_id: selectedInventory?.item.id,
            var_combo_id: selectedInventory?.var_combo?.id ?? null,
        },
    })

    // Filter selected batch serial nos
    const filteredBatchSerialNos: BatchSerial = selectedTransaction?.batch_serial_nos ? JSON.parse(JSON.stringify(selectedTransaction?.batch_serial_nos)) : []

    Object.keys(selectedBatchSerialNosData ?? {}).forEach((batch) => {
        if (filteredBatchSerialNos[batch]) {
            selectedBatchSerialNosData[batch].forEach((excludeItem: Serial) => {
                const dataItem = filteredBatchSerialNos[batch].find((item) => String(item.serial_no) === String(excludeItem.serial_no))
                if (dataItem) {
                    const newQty = parseFloat(dataItem.unit_qty) - parseFloat(excludeItem.unit_qty)
                    if (newQty > 0) {
                        dataItem.unit_qty = newQty.toFixed(0)
                    } else {
                        filteredBatchSerialNos[batch] = filteredBatchSerialNos[batch].filter((item) => String(item.serial_no) !== String(excludeItem.serial_no))
                    }
                }
            })
            if (filteredBatchSerialNos[batch].length === 0) {
                delete filteredBatchSerialNos[batch]
            }
        }
    })

    const { mutate: mutatePutawayState } = useSetPutawayState()
    const { mutate: mutateLocationLayout } = useSetLocationLayout()

    const isDirty = useMemo(() => {
        if (selectedPutawayDetail) {
            const formQty = new Decimal(formValues.qty ?? 0)
            const selectedQty = new Decimal(selectedPutawayDetail.qty)

            return (
                formValues.assigned_loc?.value !== selectedPutawayDetail.assigned_loc_id ||
                formValues.assigned_sb?.value !== selectedPutawayDetail.assigned_sb_id ||
                formValues.uom?.value !== selectedPutawayDetail.uom_id ||
                formQty.toNumber() !== selectedQty.toNumber() ||
                !!formValues.serial_nos?.length
            )
        }

        return form.formState.isDirty
    }, [formValues, selectedPutawayDetail])

    const onClose = () => {
        mutateLocationLayout({
            isOpen: false,
            selectedId: 0,
            selectedStorageBinId: 0,
            selectableStorageBinFn: undefined,
        })
        mutatePutawayState({
            selectedInventory: undefined,
            selectedTransaction: undefined,
            putawayDetail: {
                data: undefined,
                editMode: false,
            },
        })
        form.reset({
            item_id: undefined,
            var_combo_id: undefined,
            assigned_loc: undefined,
            assigned_sb: undefined,
            uom: undefined,
            qty: 0,
        })
    }

    useEffect(() => {
        if (selectedInventory) {
            form.setValue("item_id", selectedInventory.item_id)
            form.setValue("var_combo_id", selectedInventory.var_combo?.id ?? 0)

            if (!!selectedPutawayDetail) {
                const location = selectedPutawayDetail.location
                const storageBin = selectedPutawayDetail.storage_bin
                const uom = selectedPutawayDetail.uom

                if (location) {
                    form.setValue("assigned_loc", {
                        label: `${location.description} (${location.code})`,
                        value: location.id,
                    })
                    mutateLocationLayout({
                        selectedId: location.id,
                        editMode: editMode,
                        item_id: selectedInventory.item_id,
                    })
                }
                if (storageBin) {
                    form.setValue("assigned_sb", {
                        label: storageBin.name,
                        value: storageBin.id,
                    })
                }
                if (uom) {
                    form.setValue("uom", {
                        label: uom.code,
                        value: uom.id,
                        allow_decimal: !!uom.allow_decimal,
                        pivot: uom.inventory_item?.pivot,
                    })
                }
                form.setValue("qty", Number(selectedPutawayDetail.qty))
            } else if (selectedTransaction) {
                form.setValue("uom", {
                    label: selectedInventory.item.base_uom!.code,
                    value: selectedInventory.item.base_uom!.id,
                    allow_decimal: !!selectedInventory.item.base_uom!.allow_decimal,
                    pivot: selectedInventory.item.base_uom!.pivot,
                })
                form.setValue("qty", Number(selectedTransaction.total_unit_qty))
            }
        } else {
            form.reset({
                item_id: undefined,
                var_combo_id: undefined,
                assigned_loc: undefined,
                assigned_sb: undefined,
                uom: undefined,
                qty: 0,
            })
        }
    }, [selectedInventory, selectedTransaction])

    const onSubmit = (data: z.infer<typeof FormSchema>) => {
        if (id && selectedTransaction) {
            const transformedData = {
                assigned_sb_id: data.assigned_sb!.value,
                assigned_loc_id: data.assigned_loc!.value,
                uom_id: data.uom!.value,
                qty: data.qty,

                putaway_id: Number(id),
                item_id: Number(data.item_id),
                var_combo_id: Number(data.var_combo_id) > 0 ? Number(data.var_combo_id) : null,
                doc_hdr_type: selectedTransaction.doc_hdr_type,
                doc_hdr_id: selectedTransaction.doc_hdr_id,
                batch_nos: data.batch_nos,
                serial_nos: data.serial_nos,
            }
            setSubmitting(true)
            form.clearErrors()
            if (selectedPutawayDetail?.id && editMode) {
                updatePutawayDetail(
                    {
                        ...transformedData,
                        id: selectedPutawayDetail.id,
                    },
                    {
                        onSuccess: (data: Result) => {
                            if (data.isSuccess) {
                                ToastSuccess(`${intl.formatMessage({ id: "putaway_detail" })} ${intl.formatMessage({ id: "successfully_updated" })}`, data.message ?? "")
                                onClose()
                                queryClient.refetchQueries({ queryKey: ["fetch-putaway-details", Number(id)] })
                                setSubmitting(false)
                            }
                        },
                        onError: (error) => {
                            setSubmitting(false)
                            ToastError(`${intl.formatMessage({ id: "failed_to_update" })} ${intl.formatMessage({ id: "putaway_detail" })}`, error)
                        },
                        onSettled: () => {
                            setSubmitting(false)
                        },
                    }
                )
            } else {
                createPutawayDetail(transformedData, {
                    onSuccess: (data: Result) => {
                        ToastSuccess(`${intl.formatMessage({ id: "putaway_detail" })} ${intl.formatMessage({ id: "successfully_created" })}`, data.message ?? "")

                        if (data.isSuccess) {
                            onClose()
                            queryClient.refetchQueries({ queryKey: ["fetch-putaway-details", Number(id)] })
                            setSubmitting(false)
                        }
                    },
                    onError: (error) => {
                        setSubmitting(false)
                        ToastError(`${intl.formatMessage({ id: "failed_to_create" })} ${intl.formatMessage({ id: "putaway_detail" })}`, error)
                    },
                    onSettled: () => {
                        setSubmitting(false)
                    },
                })
            }
        } else {
            ToastError(intl.formatMessage({ id: "something_went_wrong" }), {
                message: intl.formatMessage({ id: "error_id_required" }),
            })
        }
    }

    return (
        <Drawer open={!!selectedInventory} direction="left" modal={false} dismissible={false}>
            <DrawerContent hideHandle position={DrawerPositionEnum.LEFT} className={cn("h-full w-1/3 min-w-[500px] pr-1")}>
                <ScrollArea className="pr-5">
                    <div className="space-y-4">
                        <DrawerTitle>{intl.formatMessage({ id: "select_item_location" })}</DrawerTitle>

                        <Form {...form}>
                            <form className="grid grid-cols-2 place-content-around gap-4" onSubmit={form.handleSubmit(onSubmit)}>
                                <FieldLabel className="col-span-2" label={intl.formatMessage({ id: "item_code" })} value={selectedInventory?.item.code ?? ""} />
                                {selectedInventory?.var_combo?.variation_option_01_id && (
                                    <FieldLabel
                                        className={!!selectedInventory.var_combo.variation_option_02_id ? "col-span-1" : "col-span-2"}
                                        label={selectedInventory.var_combo.variation_option_value01.variation?.name ?? ""}
                                        value={selectedInventory.var_combo.variation_option_value01.name ?? ""}
                                    />
                                )}
                                {selectedInventory?.var_combo?.variation_option_02_id && (
                                    <FieldLabel
                                        className="col-span-1"
                                        label={selectedInventory.var_combo.variation_option_value02.variation?.name ?? ""}
                                        value={selectedInventory.var_combo.variation_option_value02.name ?? ""}
                                    />
                                )}
                                {selectedTransaction && (
                                    <FieldLabel
                                        className="col-span-2"
                                        label={intl.formatMessage({ id: "from" })}
                                        value={selectedTransaction.document_header?.doc_code ?? ""}
                                    />
                                )}
                                <AssignedLocationField readonly={!isEditMode} isSubmitting={isSubmitting || isPendingCreate || isPendingUpdate} />
                                <AssignedStorageBinField readonly={!isEditMode} isSubmitting={isSubmitting || isPendingCreate || isPendingUpdate} item_id={selectedInventory?.item_id} />
                                <UomField readonly={!isEditMode} selectedItem={selectedInventory?.item} isSubmitting={isSubmitting || isPendingCreate || isPendingUpdate} />
                                <QuantityField
                                    readonly={!isEditMode || !!selectedPutawayDetail?.serial_no?.length}
                                    isLoading={isFetchingDetails}
                                    isSubmitting={isSubmitting}
                                />
                                {Object.entries(filteredBatchSerialNos).length > 0 && (
                                    <BatchSerialNoField
                                        readonly={!isEditMode}
                                        isLoading={isFetchingDetails}
                                        isSubmitting={isSubmitting}
                                        items={Object.entries(filteredBatchSerialNos).map((batchSerialNo) => {
                                            return {
                                                label: batchSerialNo[0] === "null" || batchSerialNo[0] === null ? intl.formatMessage({ id: "no_batch_no" }) : batchSerialNo[0],
                                                value: batchSerialNo[0] === "null" ? null : batchSerialNo[0],
                                                serials: batchSerialNo[1].map((serial: any) => {
                                                    return {
                                                        label: `${
                                                            serial.serial_no === "null" || serial.serial_no === null
                                                                ? intl.formatMessage({ id: "no_serial_no" })
                                                                : serial.serial_no
                                                        } ${serial.unit_qty > 1 ? "(" + parseFloat(serial.unit_qty).toFixed(0) + ")" : ""}`,
                                                        value: {
                                                            batch_no: batchSerialNo[0] === "null" ? null : batchSerialNo[0],
                                                            serial_no: serial.serial_no === "null" ? null : serial.serial_no,
                                                            unit_qty: serial.unit_qty,
                                                        },
                                                    }
                                                }),
                                            }
                                        })}
                                    />
                                )}
                            </form>
                        </Form>
                    </div>

                    <DrawerFooter>
                        {isEditMode && (
                            <Button
                                type="submit"
                                variant={editMode ? "warning" : "default"}
                                disabled={isSubmitting || !isEditMode || !isDirty}
                                onClick={() => {
                                    form.handleSubmit(onSubmit)()
                                }}
                            >
                                {intl.formatMessage({ id: editMode ? "update_item" : "add_item" })}

                                {isSubmitting && <Loader2 className="ml-2" />}
                            </Button>
                        )}

                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            {intl.formatMessage({ id: isEditMode ? "cancel" : "close" })}
                        </Button>
                    </DrawerFooter>
                </ScrollArea>
            </DrawerContent>
        </Drawer>
    )
}

export default SelectItemFormDrawer
