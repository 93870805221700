import useFetchLocations from "@/api/location/useFetchLocations"
import useSetLocationLayout from "@/api/location/useSetLocationLayout"
import { Button } from "@/components/ui/button"
import { Command, CommandEmpty, CommandGroup, CommandItem } from "@/components/ui/command"
import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import Loader2 from "@/components/ui/loader2"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { LocationTypeEnum } from "@/constants/LocationType"
import { cn } from "@/lib/utils"
import { Location } from "@/types/Location"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { debounce } from "lodash"
import { CheckIcon, Search } from "lucide-react"
import React, { ReactElement, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

interface Props {
    isSubmitting: boolean
    readonly?: boolean
}

const AssignedLocationField: React.FC<Props> = ({ isSubmitting, readonly }): ReactElement => {
    const intl = useIntl()
    const form = useFormContext()

    const [search, setSearch] = useState<string>()

    const { data: locationsData, isFetching } = useFetchLocations({
        param: {
            type: [LocationTypeEnum.GOOD_STOCK],
            ...(search && {
                keywords: search,
            }),
        },
    })
    const locations = Array.isArray(locationsData?.data) ? locationsData.data : []
    const { mutate: mutateLocationLayout } = useSetLocationLayout()

    const debouncedSearch = debounce((value: string) => {
        setSearch(value)
    }, 500)

    return (
        <FormField
            control={form.control}
            name="assigned_loc"
            render={({ field }) => (
                <FormItem className="col-span-2 flex flex-col">
                    <FormLabel>{intl.formatMessage({ id: "assigned_location" })}</FormLabel>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                className={cn("justify-between", !field.value && "text-muted-foreground")}
                                disabled={isSubmitting || readonly}
                            >
                                <p className="truncate">
                                    {field.value ? field.value.label : `${intl.formatMessage({ id: "select" })} ${intl.formatMessage({ id: "location" })}`}
                                </p>
                                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="p-0">
                            <Command>
                                <div className="p-2">
                                    <Input
                                        prefix={<Search className="h-4 w-4 text-muted-foreground" />}
                                        placeholder={`${intl.formatMessage({ id: "search" })} ${intl.formatMessage({ id: "location" })}`}
                                        onChange={(e) => {
                                            debouncedSearch(e.target.value)
                                        }}
                                    />
                                </div>
                                <CommandEmpty className="flex justify-center py-2">{isFetching ? <Loader2 /> : intl.formatMessage({ id: "no_results" })}</CommandEmpty>
                                <CommandGroup>
                                    {locations.map((location: Location) => {
                                        const label = `${location.description} (${location.code})`

                                        return (
                                            <CommandItem
                                                value={String(location.id)}
                                                key={`location-${location.id}`}
                                                onSelect={async () => {
                                                    field.onChange({
                                                        label: label,
                                                        value: location.id,
                                                    })
                                                    form.setValue("assigned_sb", null)
                                                    await form.trigger("assigned_loc")
                                                    mutateLocationLayout({
                                                        selectedId: location.id,
                                                    })
                                                }}
                                            >
                                                <CheckIcon className={cn("mr-2 h-4 w-4", location.id === field.value?.value ? "opacity-100" : "opacity-0")} />
                                                {label}
                                            </CommandItem>
                                        )
                                    })}
                                </CommandGroup>
                            </Command>
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

export default AssignedLocationField
