import { GeneralStatusEnum } from "./GeneralStatus"

export type PurchaseOrderStatus = GeneralStatusEnum.PENDING | GeneralStatusEnum.VOID | GeneralStatusEnum.IN_PROGRESS | GeneralStatusEnum.COMPLETED

export const PurchaseOrderStatusLabel = {
    [GeneralStatusEnum.PENDING]: "Pending",
    [GeneralStatusEnum.VOID]: "Void",
    [GeneralStatusEnum.IN_PROGRESS]: "In Progress",
    [GeneralStatusEnum.COMPLETED]: "Completed",
}

export const PurchaseOrderStatusSequence = [
    {
        status: GeneralStatusEnum.PENDING,
        optional: false,
    },
    {
        status: GeneralStatusEnum.IN_PROGRESS,
        optional: false,
    },
    {
        status: GeneralStatusEnum.COMPLETED,
        optional: false,
    },
]

export const PurchaseOrderStatusOptions = [
    { value: GeneralStatusEnum.PENDING, label: GeneralStatusEnum.PENDING },
    { value: GeneralStatusEnum.VOID, label: GeneralStatusEnum.VOID },
    { value: GeneralStatusEnum.IN_PROGRESS, label: GeneralStatusEnum.IN_PROGRESS },
    { value: GeneralStatusEnum.COMPLETED, label: GeneralStatusEnum.COMPLETED },
]

export const PurchaseOrderStatusSelectOptions = [
    { value: GeneralStatusEnum.PENDING, label: PurchaseOrderStatusLabel[GeneralStatusEnum.PENDING] },
    { value: GeneralStatusEnum.VOID, label: PurchaseOrderStatusLabel[GeneralStatusEnum.VOID] },
    { value: GeneralStatusEnum.IN_PROGRESS, label: PurchaseOrderStatusLabel[GeneralStatusEnum.IN_PROGRESS] },
    { value: GeneralStatusEnum.COMPLETED, label: PurchaseOrderStatusLabel[GeneralStatusEnum.COMPLETED] },
]
